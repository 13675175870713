var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('validation-observer',{ref:"simpleRules"},[_c('b-card',[_c('b-card-header',[_c('b-row',{staticClass:"w-100",attrs:{"no-gutters":""}},[_c('b-col',{attrs:{"md":"12"}},[_c('div',{staticClass:"d-flex justify-content-end"},[_c('div',[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(113, 102, 240, 0.15)'),expression:"'rgba(113, 102, 240, 0.15)'",modifiers:{"400":true}},{name:"b-popover",rawName:"v-b-popover.hover.focus",value:(
                    '⚪️ Le questionnaire de renseignements de sortie de l\'aéroport de Cotonou est éditable par les voyageurs entrant au Bénin par l\'aéroport et les annexes. ⚪️ Il permet de collecter les données sur les voyageurs et leur séjours. ⚪️ Le remplissage de la fiche ne nécessite pas une connexion ou la création d\'un compte.'
                  ),expression:"\n                    '⚪️ Le questionnaire de renseignements de sortie de l\\'aéroport de Cotonou est éditable par les voyageurs entrant au Bénin par l\\'aéroport et les annexes. ⚪️ Il permet de collecter les données sur les voyageurs et leur séjours. ⚪️ Le remplissage de la fiche ne nécessite pas une connexion ou la création d\\'un compte.'\n                  ",modifiers:{"hover":true,"focus":true}}],staticClass:"btn-icon rounded-circle",attrs:{"size":"lg","variant":"outline-primary","title":"Informations"}},[_c('feather-icon',{attrs:{"icon":"BellIcon"}})],1)],1)])]),_c('b-col',{attrs:{"md":"2"}},[_c('b-card-img',{staticClass:"rounded-0 my-2",attrs:{"src":require('@/assets/images/illustration/Globalization-pana.png'),"alt":"Image"}})],1),_c('b-col',{staticClass:"d-flex align-items-center justify-content-center",attrs:{"md":"10"}},[_c('div',[_c('br'),_c('h4',{staticClass:"m-auto text-center"},[_vm._v(" QUESTIONNAIRE DE RENSEIGNEMENTS A L'AÉROPORT DE COTONOU (SORTIE) ")])])])],1)],1),_c('b-card-body',[_c('div',[_c('b-row',[_c('b-col',{staticClass:"mt-2",attrs:{"md":"12"}},[_c('validation-provider',{attrs:{"name":"Date","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label-cols-lg":"2","label":"Date de départ du Bénin","label-for":"date"}},[_c('flat-pickr',{staticClass:"form-control",attrs:{"placeholder":"Date","value":"date","type":"date"},model:{value:(_vm.sendForm.dateDepart),callback:function ($$v) {_vm.$set(_vm.sendForm, "dateDepart", $$v)},expression:"sendForm.dateDepart"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1)],1),_c('b-row',[_c('b-col',{staticClass:"mt-2",attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label-cols-lg":"2","label":"Compagnie aérienne"}},[_c('validation-provider',{attrs:{"name":"Compagnie Aérienne","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"Air France"},model:{value:(_vm.sendForm.compagnieAerienne),callback:function ($$v) {_vm.$set(_vm.sendForm, "compagnieAerienne", _vm._n($$v))},expression:"sendForm.compagnieAerienne"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{staticClass:"mt-2",attrs:{"md":"12"}},[_c('validation-provider',{attrs:{"name":"Pays de Résidence","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label-cols-lg":"2","label":"Pays de résidence","label-for":"pays_residence"}},[_c('v-select',{attrs:{"type":"text","options":_vm.pays_residence,"label":"title","placeholder":"Pays de résidence","size":"sm"},model:{value:(_vm.sendForm.paysResidence),callback:function ($$v) {_vm.$set(_vm.sendForm, "paysResidence", $$v)},expression:"sendForm.paysResidence"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1)],1),(
              _vm.sendForm.paysResidence == 'Bénin' ||
              _vm.sendForm.paysResidence == '' ||
              _vm.sendForm.paysResidence == null
            )?_c('span',[_c('b-row',[_c('b-col',{staticClass:"mt-2",attrs:{"md":"12"}},[_c('validation-provider',{attrs:{"name":"Nationalité","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label-cols-lg":"2","label":"Nationalité","label-for":"nationality"}},[_c('v-select',{attrs:{"type":"text","options":_vm.nationality,"label":"title","placeholder":"Nationalité"},model:{value:(_vm.sendForm.nationalite),callback:function ($$v) {_vm.$set(_vm.sendForm, "nationalite", $$v)},expression:"sendForm.nationalite"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,false,1533301863)})],1)],1),_c('b-row',[_c('b-col',{staticClass:"mt-2",attrs:{"md":"12"}},[_c('validation-provider',{attrs:{"name":"Pays de destination","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label-cols-lg":"2","label":"Pays de destination","label-for":"pays_destination"}},[_c('v-select',{attrs:{"type":"text","options":_vm.pays_destination,"label":"title","placeholder":"Pays de destination"},model:{value:(_vm.sendForm.paysDestination),callback:function ($$v) {_vm.$set(_vm.sendForm, "paysDestination", $$v)},expression:"sendForm.paysDestination"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,false,2562834566)})],1)],1),_c('b-row',[_c('b-col',{staticClass:"mt-2",attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label-cols-lg":"2","label":"Durée du séjour (Mois - Jours)","label-for":"duree"}},[_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"d-inline-block w-50"},[_c('b-form-input',{attrs:{"placeholder":"Nombre de mois","type":"number","md":"6"},model:{value:(_vm.dureeSejourMois),callback:function ($$v) {_vm.dureeSejourMois=_vm._n($$v)},expression:"dureeSejourMois"}})],1),_c('div',{staticClass:"d-inline-block w-50"},[_c('validation-provider',{attrs:{"name":"Durée","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"Nombre de jours","type":"number","md":"6"},model:{value:(_vm.dureeSejourJours),callback:function ($$v) {_vm.dureeSejourJours=_vm._n($$v)},expression:"dureeSejourJours"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,4288486697)})],1)])])],1)],1),_c('b-row',[_c('b-col',{staticClass:"mt-2",attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label-cols-lg":"2","label-size":"lg","label":"Motif Principal de Voyage","label-for":"motif_travel"}},[_c('validation-provider',{attrs:{"name":"Motif Principal de Voyage","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('b-form-radio-group',{attrs:{"options":_vm.motif_travel,"value-field":"id","text-field":"name","name":"some-radio9","stacked":""},model:{value:(_vm.sendForm.motifVoyage),callback:function ($$v) {_vm.$set(_vm.sendForm, "motifVoyage", $$v)},expression:"sendForm.motifVoyage"}}),(_vm.sendForm.motif_travel == 'Autres (Á preciser)')?_c('b-form-input',{staticClass:"mt-2",attrs:{"placeholder":"Autres","type":"text"},model:{value:(_vm.sendForm.motifVoyage),callback:function ($$v) {_vm.$set(_vm.sendForm, "motifVoyage", $$v)},expression:"sendForm.motifVoyage"}}):_vm._e(),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3752856582)})],1)],1)],1),_c('b-row',[_c('b-col',{staticClass:"mt-2",attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label-cols-lg":"2","label":"Mode d'hébergement","label-for":"hebergment"}},[_c('validation-provider',{attrs:{"name":"Hebergement","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('b-form-radio-group',{staticClass:"demo-inline-spacing",attrs:{"value-field":"id","text-field":"name","options":_vm.hebergement,"name":"radio-validation"},model:{value:(_vm.sendForm.modeHebergement),callback:function ($$v) {_vm.$set(_vm.sendForm, "modeHebergement", $$v)},expression:"sendForm.modeHebergement"}}),(_vm.sendForm.modeHebergement == 'Autres (Á preciser)')?_c('b-form-input',{staticClass:"mt-2",attrs:{"placeholder":"Autres","type":"text"},model:{value:(_vm.autresModeHebergement),callback:function ($$v) {_vm.autresModeHebergement=$$v},expression:"autresModeHebergement"}}):_vm._e(),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2829416645)})],1)],1)],1),_c('b-row',[_c('b-col',{staticClass:"mt-2",attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label-cols-lg":"2","label":" Allez-vous percevoir une rémunération d'une entité du pays / lieu visité durant votre séjour ?","label-for":"remuneration"}},[_c('validation-provider',{attrs:{"name":"Remuneration","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('b-form-radio-group',{staticClass:"demo-inline-spacing",attrs:{"options":_vm.remuneration,"name":"radio-inline"},model:{value:(_vm.sendForm.possibiliteRemuneration),callback:function ($$v) {_vm.$set(_vm.sendForm, "possibiliteRemuneration", $$v)},expression:"sendForm.possibiliteRemuneration"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1523908738)})],1)],1)],1),_c('b-row',[_c('b-col',{staticClass:"mt-2",attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label-cols-lg":"2","label":"Age","label-for":"age"}},[_c('validation-provider',{attrs:{"name":"Age","rules":"required|integer"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"Age","type":"number"},model:{value:(_vm.sendForm.age),callback:function ($$v) {_vm.$set(_vm.sendForm, "age", _vm._n($$v))},expression:"sendForm.age"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,4129776255)})],1)],1)],1),_c('b-row',[_c('b-col',{staticClass:"mt-2",attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label-cols-lg":"2","label":"Sexe","label-for":"sexe"}},[_c('validation-provider',{attrs:{"name":"Sexe","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('b-form-radio-group',{staticClass:"demo-inline-spacing",attrs:{"options":_vm.sexe,"name":"radio-sexe"},model:{value:(_vm.sendForm.sexe),callback:function ($$v) {_vm.$set(_vm.sendForm, "sexe", $$v)},expression:"sendForm.sexe"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2968074921)})],1)],1)],1),_c('b-row',[_c('b-col',{staticClass:"mt-2",attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label-cols-lg":"2","label":"Nom et Prénom","label-for":"nom_prenom"}},[_c('validation-provider',{attrs:{"name":"Nom & Prenom","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"Nom et Prénom","type":"text"},model:{value:(_vm.sendForm.fullname),callback:function ($$v) {_vm.$set(_vm.sendForm, "fullname", $$v)},expression:"sendForm.fullname"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2344214239)})],1)],1)],1)],1):_vm._e(),_c('b-row',[_c('b-col',{staticClass:"mt-2",attrs:{"md":"12"}},[_c('validation-provider',{attrs:{"name":"Nom_Enqueteur","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label-cols-lg":"2","label":"Nom de l'enquêteur","label-for":"enqueteur"}},[_c('b-form-input',{attrs:{"placeholder":"Nom de l'enquêteur"},model:{value:(_vm.sendForm.enqueteur),callback:function ($$v) {_vm.$set(_vm.sendForm, "enqueteur", _vm._n($$v))},expression:"sendForm.enqueteur"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1)],1)],1)])],1),_c('div',{staticClass:"text-right"},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"col-xl-2",attrs:{"type":"submit","variant":"primary"},on:{"click":function($event){return _vm.sendInfosSortieAeroport()}}},[_vm._v(" Aperçu avant enregistrement ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }